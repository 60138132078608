<template>
    <div id="event-registrations">
        <!-- {{event.attendees}} -->
        <!-- <h1>Registrations</h1> -->
        <div class="cta-container">
            <div class="info-container">
                <h1>Registrants</h1>
                <!-- <p>{{(isLoadingInfo)?'Loading...':`${event.attendees && event.attendees.total} Total registrations (${event.attendees && event.attendees.checkin} check - ins)`}}</p> -->
            </div>
        </div>
        <div class="filter-container">
            
            <div class="filter-group stretch">
                <div class="filter stretch">
                    <v-text-field
                        v-model="queryString"
                        color="#34626C"
                        height="40"
                        outlined
                        block
                        prepend-inner-icon="search"
                        placeholder="Find attendee"
                    ></v-text-field>

                </div>
                
            </div>
            <div class="filter-group">
                <div class="filter">
                    <v-select
                        color="#34626C"
                        v-model="registrationStatus"
                        height="40"
                        outlined
                        block
                        :items="['All','Confirmed', 'Checkin']"
                        
                        placeholder="Status"
                        item-text="name"
                        return-object
                    ></v-select>
                </div>
                
            </div>
        </div>
        <br>
        <div class="no-data" v-if="attendees.length === 0">
            <h1>No results found</h1>
        </div>
        <!-- {{order_by}} - {{order_dir}} -->
        <div class="results-table" v-if="attendees.length > 0">
            <p>Total Results: {{totalResults}}</p>
            <div class="results">

                <template>
                <v-data-table
                    :headers="headers"
                    :items="attendees"
                    :options.sync="options"
                    :server-items-length="totalResults"
                    hide-default-footer
                    :loading="loading"
                    class="elevation-0">
                    
                    <template v-slot:item.status="{ item }">
                        <span
                            :class="item.status"
                        >
                            {{ item.status }}
                        </span>
                        </template>

                    <template v-slot:item.block="{ item }">
                        <v-checkbox
                            color="#34626C"
                            :input-value="item.block === 'true' || item.block === true || item.block === '1' || item.block === 1"
                            @click.stop="updateBanStatus(item)"
                            
                        ></v-checkbox>
                    </template>

                </v-data-table>
                </template>


                


            </div>

            <div class="controls">
                <div class="result-amount">
                    <label>Displaying</label>
                    <v-select
                        outlined
                        color="#34626C"
                        v-model="displayResults"
                        height="40"
                        :items="displayResultsOptions"
                    ></v-select>
                </div>
                <div class="pagination">
                    <v-pagination
                        class="elevation-0"
                        color="#34626C"
                        v-model="currentPage"
                        :length="availablePages"
                        :total-visible="7"
                    ></v-pagination>
                </div>
                <div class="go-to-page">
                    <label>Page</label>
                    <v-select
                        outlined
                        color="#34626C"
                        v-model="currentPage"
                        height="40"
                        :items="availablePagesList"
                    ></v-select>
                </div>
            </div>
        </div>


        


    </div>
</template>
<script>
// import TextInput from '../../components/TextInput/TextInput.vue';
import { required, minLength, email } from 'vuelidate/lib/validators';
import EventModel from '../../lib/EventModel';
import SandiaEventModel from '../../lib/SandiaEventModel';
import lancome_stores from '../../lib/lancome_stores.json'
import io from 'socket.io-client';
import CONSTANTS from '../../lib/Constants';

export default {
    components:{},
    props:['event', 'eventMongo'],
    data(){
        return{
            lancome_stores,
            isLoadingInfo:false,
            eventInfo:{
                registrations: 0,
                checkins: 0
            },
            stores:[{name:'All'},{name:'A'}],
            selectedStore:null,
            queryString:'',
            switchCheckins:false,
            switchRegistered:false,
            attendees:[],
            displayResults: 10,
            currentPage:1,
            totalResults: 0,
            displayResultsOptions:[10,25,50,100],
            headers:[
                { text: 'First Name', value: 'firstname' },
                { text: 'Last Name', value: 'lastname' },
                { text: 'Email', value: 'email' },
                // { text: 'Store Name', value: 'store' },
                { text: 'Status', value: 'status' },
                { text: 'Banned', value: 'block', sortable: false }
            ],
            loading: false,
            options: {},
            showCreateDialog: false,
            mode: 'create',

            //Form Variables
            firstName:'',
            lastName:'',
            email:'',
            phone:'',
            formStore:null,
            formGoals:[],
            registrationStatus:'',
            awaitingSearch:false,
            order_by:'email',
            order_dir:'DESC',
            socket: null, //io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['websocket', 'polling']}),


        }
    },
    validations: {
        firstName: {
            required,
            minLength: minLength(2)
        },
        lastName:{
            required,
            minLength: minLength(4),
        },
        email: {
            minLength: minLength(4),
            email
        },
        phone:{
            minLength: minLength(4),
        },
        formGoals:{
            required
        },
    },
    computed:{
        availablePages(){
           return Math.ceil(this.totalResults  / this.displayResults);
        },
        availablePagesList(){
            let pages = [];
            for(let i = 0; i < this.availablePages; i++){
                pages.push(i+1);
            }
            return pages;
        },
        firstNameError(){
            return this.getErrorMessage(this.$v.firstName);
        },
        lastNameError(){
            return this.getErrorMessage(this.$v.lastName);
        },
        emailError(){
            return this.getErrorMessage(this.$v.email);
        },
        phoneError(){
            return this.getErrorMessage(this.$v.phone);
        },
    },
    methods:{
        showCreateNewDialog(){
            this.mode = 'create';

            this.firstName = '';
            this.lastName = '';
            this.email = '';
            this.phone = '';
            this.formStore = null;
            this.formGoals = [];

            this.$v.firstName.$reset();
            this.$v.lastName.$reset();
            this.$v.email.$reset();
            this.$v.phone.$reset();

            this.showCreateDialog = true;
        },
        registerEvents(){
            if(!this.socket){
                console.warn('NO SOCKET YET!!!');
                return;
            }

            this.socket.on('connect', ()=>{
                console.log('connected'); 
                console.log(this.eventMongo);
            });

            this.socket.emit('chat:signin', {
                type: 'signin',
                event: `ostt-${this.eventMongo._id}`,
                id: 1
            });

            this.socket.on('chat:room',()=>{
                    console.log('Connected to ');
                    console.log(`ostt-${this.eventMongo._id}`);
                });
            // Reconnect
            this.socket.on('reconnect', () => {
                this.socket.emit('chat:signin',{
                    type: 'reconnect',
                    event: `ostt-${this.eventMongo._id}`,
                    id: 1
                });
            });
        },
        async fetchResults(){
            //const { sortBy, sortDesc, page, itemsPerPage } = this.options
            console.log(this.options);

            try{
                let filter = {
                    
                }
                console.log(filter);
            }
            catch(e){
                debugger;
                console.error(e);
            }

            this.loading = false;
        },
        getErrorMessage(model){
            if(model == null || !model.$dirty){
                return '';
            }
            
            let message = '';
            // debugger;
            if(typeof(model.required) !== 'undefined' && !model.required){
                message = "This field is required";
            }
            if(typeof(model.minLength) !== 'undefined' && !model.minLength){
                message = "You must enter at least " + model.$params.minLength.min + " characters";
            }
            if(typeof(model.sameAsPassword) !== 'undefined' && !model.sameAsPassword){
                message = "Entered value doesn't match";
            }
            if(typeof(model.email) !== 'undefined' && !model.email){
                message = "Value must be valid email";
            }
           
            return message;
        },
        isFormValid(){
            let isValid = true;

            if(this.$v.email && this.$v.email.$invalid){
                isValid = false;
            }
            if(this.$v.password && this.$v.password.$invalid){
                isValid = false;
            }
            console.log(this.$v);
            return isValid;
        },
        async handleFormSubmit(){
            let store = (this.formStore)?this.formStore.value:'';
            let goals = (this.formGoals && Array.isArray(this.formGoals) && this.formGoals.length > 0)?this.formGoals.join():'';

            let attendee = {
                                firstname: this.firstName.trim(),
                                lastname: this.lastName.trim(),
                                email: this.email.trim() || '',
                                event: this.event.sandia,
                                phone: this.phone || '',
                                store: store,
                                lashgoals: goals
                            };
            // console.log(attendee);
            // debugger;
            await this.runFunctionWithLoader(async ()=>{
                await EventModel.addNewAttendee(attendee);
                this.showCreateDialog = false;
            });
            
        },

        async updateBanStatus(item){
            // alert(item.id);
            // let event_id = this.event._id;
            let mongo_event_id = this.eventMongo._id;
            // let attendee_id = item.id;
            let attendee_rsvp = item.rsvp;
            console.log(item);

            // if(item){
            //     return;
            // }

            if(item.block === 'true' || item.block === true || item.block === '1' || item.block === 1){
                await EventModel.blockUser(item,false);
                item.block = 0;
                console.log(this.socket.emit('attendee:unlock',{
                    event: mongo_event_id, 
                    // attendee: attendee_id,
                    attendee: attendee_rsvp
                }));
                
            }
            else{
                await EventModel.blockUser(item,true);
                item.block = 1;

                console.log(this.socket.emit('attendee:block',{
                    event: mongo_event_id, 
                    // attendee: attendee_id,
                    attendee: attendee_rsvp
                }));

            }
        },

        async getFilteredAttendeeInfo(){

            let firstRecord = (this.currentPage - 1) * this.displayResults || 0;
            let status = (this.registrationStatus && this.registrationStatus.toLowerCase() !== 'all')?this.registrationStatus.toLowerCase():'';
            // let store = (this.selectedStore && typeof(this.selectedStore.value) === 'string' && this.selectedStore.value.toLowerCase() !== 'all')?this.selectedStore.value.trim():'';
            // let order_by = (this.object && this.object.sortBy && this.object.sortBy[0])?this.object.sortBy[0]:'email';
            // let order_dir = (this.object && this.object.sortDesc && this.object.sortDesc[0] && this.object.sortDesc[0] === false)?'ASC':'DESC';
            // debugger;

            let filter = {
                "first_record":firstRecord,
                "record_limit":this.displayResults,
                "order_by":this.order_by,
                "order_dir":this.order_dir,
                "term": this.queryString,
                "status":status
            }

            let count, users = 0;
            try{
                // ({count, users} = await EventModel.getFilteredAttendees(this.event.sandia,filter));
                ({count, users} = await SandiaEventModel.getEventAttendees(this.event.id,filter));
            }
            catch(e){
                if(e){
                    console.log(e);
                }
            }
            
            
            this.totalResults = count;
            this.attendees = users;
        },
        async updateEventAttendeeInfo(){
            if(!this.event){
                return;
            }
            let attendees = await EventModel.getAttendeeCount(this.event.sandia);
            this.$set(this.event,'attendees', attendees);
        },
        async refreshAttendeeInfo(){
            // if(this.loading){
            //     return;
            // }
            this.loading = true;
            // await this.runFunctionWithLoader(this.getFilteredAttendeeInfo);
            await this.getFilteredAttendeeInfo();
            await this.updateEventAttendeeInfo();
            this.loading = false;
        },

        async runFunctionWithLoader(userFunction){
            if(typeof(userFunction) === 'function'){
                try{
                    this.$emit('show_loader');
                    await userFunction();
                }
                catch(error){
                    console.error(error);
                    debugger;
                }
                finally{
                    this.$emit('hide_loader');
                }
            }
        }


    },
    watch:{
        selectedStore(newVal){
            if(newVal && newVal.label === "All"){
                this.selectedStore = null;
            }
            this.currentPage = 1;
            this.refreshAttendeeInfo();
        },
        registrationStatus(newVal){
            if(newVal && newVal.toLowerCase() === "all"){
                this.registrationStatus = null;
            }
            this.currentPage = 1;
            this.refreshAttendeeInfo();
        },
        displayResults(){
            this.currentPage = 1;
            this.refreshAttendeeInfo();
        },
        currentPage(){
            this.refreshAttendeeInfo();
        },
        queryString() {
            if (!this.awaitingSearch) {
            setTimeout(() => {
                this.currentPage = 1;
                this.refreshAttendeeInfo();
                this.awaitingSearch = false;
            }, 1000); // 1 sec delay
            }
            this.awaitingSearch = true;
        },
        // totalResults(){
        //     this.currentPage = 1;
        //     //this.fetchResults();
        // },
        // attendees(){
        //     this.totalResults = this.attendees.length;
        // },
        options: {
            handler (newVal) {
                // console.log(this.options);
                // debugger;
                this.order_by = (newVal && newVal.sortBy && newVal.sortBy[0])?newVal.sortBy[0]:'email';
                this.order_dir = (newVal && newVal.sortDesc && newVal.sortDesc[0])?'DESC':'ASC';
                this.refreshAttendeeInfo();
            },
            deep: true,
        },
        event(){
            
            if(this.event){
                this.refreshAttendeeInfo();
            }
        },
        eventMongo(){
            if(this.eventMongo && this.eventMongo._id){
                this.socket = io(CONSTANTS.SOCKET_SERVER_URL,{secure: true, transports: [ 'websocket', 'polling']});
                this.registerEvents();
            }
            
        }
    },
    mounted(){
        // if(this.event){
            if(this.eventMongo && this.eventMongo._id){
                this.socket = io(CONSTANTS.SOCKET_SERVER_URL,{secure: true, transports: ['websocket', 'polling']});
                this.registerEvents();
            }
            this.refreshAttendeeInfo();
        // }
        //this.totalResults = this.attendees.length;
    }
}
</script>
<style lang="scss">
#event-registrations{

    .v-btn.v-btn--disabled{
        background-color: rgb(204, 71, 134);
        border-color: rgb(204, 71, 134);
        opacity:0.8;
    }

    // fieldset{
    //         color: #E4E8E8 ;
    //         // border-color: #E4E8E8 ;
    // }

    // .v-text-field.v-text-field--outlined{
    //     border-color: #E4E8E8 ;
    // }

    // fieldset.v-input--is-focused{
    //     border-color:red !important;
    // }


    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }

    margin: 43px 0 0 auto;
    // width: 90%;
    // max-width: 1200px;
    .cta-container{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;

        .info-container{
            
            flex: 1;
            display: flex;
            justify-content: center;
            flex-flow: column;
            h1{
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #363738;
                margin:0;
            }
            p{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #6C6E6F;
                margin:0;
            }
        }
    }

    .filter-container{
        margin-top:5px;
        display:flex;
        flex-flow: row wrap;
        .filter-group{
            display:flex;
            flex-flow: row wrap;
            .filter{
                display:flex;
                flex-flow: row wrap;
                margin-left: 5px;


                .v-text-field__details{
                    display: none;
                }


                .v-input__slot{
                    background-color:white;
                }

                .v-input__prepend-inner{
                    z-index:10;
                }


            }
        }
    }

    .single-radio-input{
        height: 40px;
        display:flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E4E8E8;
        background-color: white;
        padding: 0 12px;
        border-radius: 4px;
    }

    .v-input--selection-controls__input{
        .v-label{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 140%;
        }
    }

    .stretch{
        flex-grow:1;
        flex-shrink: 0;
    }
    .noshrink{
        flex-shrink: 0;
    }

    .outlined {
        border: 1px solid red;
        border-radius:50%;
        height: 56px;
        width: 56px;
    }

    .no-data{
        border: 1px solid #E4E8E8;
        background-color: white;
        padding: 0 12px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height:60px;

        h1{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            /* identical to box height */

            text-align: center;
        }
    }

    .controls{
        margin-top:25px;
        display: flex;
        .result-amount, .go-to-page{
            width:10%;
        }
        .pagination{
            display:flex;
            flex:1;
            justify-content: center;
        }

        .result-amount{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            display: flex;
            align-items: center;
            label{
                margin-right: 5px;
            }

            .v-text-field__details{
                display:none;
            }
            .mdi-menu-down::before{
                background: none;
                width:0;
                height:0;
            }
            .v-input__append-inner{
                display:none;
            }

        }
        .go-to-page{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            display: flex;
            align-items: center;
            label{
                margin-right: 5px;
            }

            .v-text-field__details{
                display:none;
            }
            .mdi-menu-down::before{
                background: none;
                width:0;
                height:0;
            }
            .v-input__append-inner{
                display:none;
            }
        }


        .v-pagination__navigation,
        .v-pagination__item{
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
        .v-pagination__item.v-pagination__item--active{
            color:#34626C;
            background-color: transparent !important;
            // text-decoration: underline;
            border-bottom: 1px solid #34626C;
            border-radius: 0;
        }

        .v-icon.notranslate.mdi.mdi-chevron-left,
        .v-icon.notranslate.mdi.mdi-chevron-right{
            color:#34626C;
        }


    }

    th{
        background-color: #E4E8E8;
        text-transform: uppercase;
        font-family: graphikmedium;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.25em;
        text-align: center;
        color: #34626C;
    }

    td{
        font-family: graphikregular;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

    }

    .registered, .confirmed{
        color: #23ADFA;
    }

    .checkin{
        color:#19CCAC;
    }

    .create-edit-form{
        background: #FFFFFF;
        // box-shadow: 0px 20px 40px rgba(255, 240, 250, 0.8);
        border-radius: 8px;
        padding:25px;

        width:95vw;
        max-width: 465px;

        .title{
            margin-bottom:15px;
            display:flex;
            justify-content: space-between;
            align-items: center;

            

            i:hover{
                cursor:pointer;
            }
            h1{
                color:#363738;
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                text-transform: capitalize;
            }
        }
        .lancome-textfield label{
            color:#363738;
        }
        .form-input > input{
            width: 100%;
        }

        .v-application.error,
        .lancome-textfield.error {
            background-color: transparent !important;
            label{
                color:red !important;
            }
        }
        color:#363738;
        *::placeholder{
                color:A2A9A9, 100%;
            }
        .v-select__selections > *{
            color:#363738;
            *::placeholder{
                color:A2A9A9, 100%;
            }
        }

        .v-input__control{
            color:#E4E8E8;
        }

        label{
            font-family: graphikmedium;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #363738;
            display: block;
        }

        

        .goals{
            display: flex;
            justify-content: row wrap;
            .col{
                min-width: 50%;

            }


            .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
            color: #E4E8E8;
            }
            .v-icon.v-icon{
                color: #E4E8E8;
            }
            .v-messages.theme--light{
                display:none;
            }
            .v-input__slot{
                margin-bottom:0;
            }



        }

        .v-input--checkbox{
            .v-label{
                font-family: graphikregular;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;
                color:#363738;
            }
        }

        .controls{
            display: flex;
            justify-content: space-between;

            .v-btn.v-btn--flat.v-btn--plain{
                span{
                    color: #B6BDBD !important;
                }
            }

        }

        




    }
}
</style>