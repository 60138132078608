<template>
    <div id="lancome-admin-event-registrants">
        <Loading :show="showLoader"/>
        <NavBar />
        <EventInfoBar :event="event" :selected="0"/>
        <!-- <pre>{{event}}</pre>
        <pre>{{eventMongo}}</pre> -->
        <div class="info-container">
            <router-view 
                v-on:show_loader="showLoader = true" 
                v-on:hide_loader="showLoader = false" 
                :event="event" 
                :eventMongo="eventMongo"
                :key="$route.path" 
            />
        </div>
    </div>
</template>
<script>
import NavBar from '../components/NavBar/NavBar.vue';
import EventInfoBar from '../components/EventInfoBar/EventInfoBar.vue';
import Loading from '../components/Loading/Loading.vue';
// import axios from 'axios';
import CONSTANTS from '../lib/Constants.js'
import EventModel from '../lib/EventModel.js';
import SandiaEventModel from '../lib/SandiaEventModel.js';

export default {
    components:{
        NavBar,
        EventInfoBar,
        Loading
    },
    props:['event_id'],
    data(){
        return {
            showLoader:false,
            CONSTANTS,
            EventModel,
            event:{},
            eventsMongo:[],
        }
    },
    methods:{
        async fetchEventInfo(){
            this.event = await SandiaEventModel.getEvent(this.event_id);
        },
        async fetchMongoEventInfo(){
            this.eventsMongo = await EventModel.fetchMongoEventInfo();
        },
        navigateToEvent(event){
            //alert(JSON.stringify(event));
            this.$router.push({name:'Admin', params:{event:event}});
        },
        async loadData(){
            try{
                this.showLoader = true;
                await this.fetchEventInfo();
                await this.fetchMongoEventInfo();
            }
            finally{
                this.showLoader = false;
            }
        }
    },
    watch:{
        event_id(){
            this.loadData();
        }
    },
    computed:{
        eventMongo(){
            if(!this.eventsMongo || !this.event){
                return null;
            }
            const event = this.eventsMongo.find(ev => ev.sandia === this.event_id);
            return event;
        }
    },
    async mounted(){
        await this.loadData();
    }
}
</script>
<style lang="scss">
#lancome-admin-event-registrants{
    .info-container{
        width:90%;
        max-width: 1200px;
        margin: 0 auto;
    }

}

</style>