import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue';
import User from '../lib/User';
import AdminMain from '../views/AdminMain.vue'
// import AdminEventRegistrants from '../views/AdminEventRegistrants.vue'
import AdminEventInfoContainer from '../views/AdminEventInfoContainer.vue'
import EventRegistrations from '../views/partial/EventRegistrations.vue';
import EventGame from '../views/partial/EventGame.vue';
import EventQuestions from '../views/partial/EventQuestions.vue';
import EventReports from '../views/partial/EventReports.vue';
import EventChat from '../views/partial/EventChat.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminMain,
    meta:{requiresLocalAuth:true},
    
  },
  {
    path:'/admin/event/:event_id',
    name:'AdminEventRegistrants',
    component: AdminEventInfoContainer,
    meta:{requiresLocalAuth:true},
    props:true,
    children: [
      {
        path: 'registrations',
        name: 'registrations',
        component: EventRegistrations,
        meta:{requiresLocalAuth:true},
      },
      {
        path: 'games',
        name: 'games',
        component: EventGame,
        meta:{requiresLocalAuth:true},
      },
      {
        path: 'questions',
        name: 'questions',
        component: EventQuestions,
        meta:{requiresLocalAuth:true},
      },
      {
        path: 'reports',
        name: 'reports',
        component: EventReports,
        meta:{requiresLocalAuth:true},
      },
      {
        path: 'chat-moderation',
        name: 'chat-moderation',
        component: EventChat,
        meta:{requiresLocalAuth:true},
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth){
    if(User.isAuthenticated()){
      next();
    }
    else{
      next({ name: 'Login' });
    }
  } 
  if(to.meta.requiresLocalAuth){
    let username = localStorage.getItem('firstName') + localStorage.getItem('lastName');
    let isAuthenticated = User.isAuthenticated();
    if(!isAuthenticated || !username){
      next({ name: 'Login' });
    }
    else{
      next();
    }
  }
  else {
    next();
  }
})

export default router
