import jwt from 'jsonwebtoken';
import Auth from './Auth'

const User = {
    isAuthenticated : ()=>{
        let credentials = Auth.fetchCredentials();

        

        if(credentials.token && credentials.id){
            return true;
        }
        
    },
    getUsername : () =>{
        let credentials = Auth.fetchCredentials();
        if(!credentials.access_token || !credentials.id_token){
            throw('No valid credentials found')
        }
        const decoded = jwt.decode(credentials.id_token);
        if(decoded.preferred_username){
            return decoded.preferred_username;
        }
        else{
            throw('No valid username found')
        }
    },
    getUserEmail : () =>{
        let credentials = Auth.fetchCredentials();
        if(!credentials.access_token || !credentials.id_token){
            throw('No valid credentials found')
        }
        const decoded = jwt.decode(credentials.id_token);
        if(decoded.preferred_username){
            return decoded.email;
        }
        else{
            throw('No valid email found')
        }
    }

}

export default User;