<template>
    <div class="report-section registrationbreakdown">
        <div class="report-title">
            <h1>Registration breakdown</h1>
            <!-- <p></p> -->
        </div>
        <!-- {{event.attendees}} -->
        <div class="report-row">
            <div class="report-card single-value nopadding">
                <h1>{{(event.attendees.total)?event.attendees.total:0}}</h1>
                <p>Total user registered</p>
            </div>
            <div class="report-card dual-value nopadding">
                <h1>{{event.attendees.checkin}} <span class="grey">/</span> <span class="fade">{{checkinPercentage}}%</span></h1>
                <p>Total user checked - in / Percentage</p>
            </div>
        </div>

        <div class="report-row">
            <div class="report-card fullwidth">
                <div class="title">Registrations over time</div>
                <div class="graph">
                    <area-chart :colors="['#34626C', '#666']" :data="registrationsOverTimeChartData"></area-chart>
                </div>
            </div>
            
        </div>


        <div class="report-row">
            <div class="report-card fullwidth">
                <div class="title">Checkins over time</div>
                <div class="graph">
                    <area-chart :colors="['#34626C', '#666']" :data="checkinsOverTimeChartData"></area-chart>
                </div>
            </div>
            
        </div>


    </div>
</template>
<script>
import EventModel from '../../../lib/EventModel';
export default {
    props:['event'],
    data(){
        return{
            registrationsAndCheckinsOverTimeChartData:null
        }
    },
    computed:{
        checkinPercentage(){
            let totalUsers = (this.event && this.event.attendees && this.event.attendees.total)?this.event.attendees.total:0;
            let checkinUsers = (this.event && this.event.attendees && this.event.attendees.checkin)?this.event.attendees.checkin:0;
            let percentage = 0;

            if(checkinUsers === 0){
                return percentage;
            }
            percentage = Math.round((checkinUsers * 100)/totalUsers);
            return percentage;
        },
        registrationsOverTimeChartData(){
            if(!this.registrationsAndCheckinsOverTimeChartData){
                return null
            }
            let registrationsOverTime = this.registrationsAndCheckinsOverTimeChartData.registrations;
            
            let aux = {};
            
            for(let i = 0; i < registrationsOverTime.length; i++){
                let el = registrationsOverTime[i];
                if(el.datevalue){
                    aux[el.datevalue] = el.total;
                }
            }

            // console.log(aux);
            
            return aux;
        },
        checkinsOverTimeChartData(){
            if(!this.registrationsAndCheckinsOverTimeChartData){
                return null
            }
            let checkinsOverTime = this.registrationsAndCheckinsOverTimeChartData.checkins;
            
            let aux = {};
            
            for(let i = 0; i < checkinsOverTime.length; i++){
                let el = checkinsOverTime[i];
                if(el.datevalue){
                    aux[el.datevalue] = el.total;
                }
            }

            // console.log(aux);
            
            return aux;
        },
        
    },
    methods:{
        async loadChartData(){
            if(this.event && this.event.sandia){
               this.registrationsAndCheckinsOverTimeChartData = await EventModel.getRegistrationsAndCheckinOverTime(this.event.sandia);
               
            }
        }
    },
    watch:{
        event(){
            this.loadChartData();
        }
    },
    async mounted(){
        await this.loadChartData();
    }
}
</script>
<style lang="scss">
    .report-section{
        margin-top:40px;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;

        .report-title{
            margin-bottom:27px;
            text-align: left;
            h1{
                font-family: graphikmedium;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                color: #363738;
            }
        }
        .graph{
            margin:30px 0 0 0;
        }

        .report-row{
            display:flex;
            justify-content: space-between;
            margin:15px 0;
            

            .report-card{
                display: flex;
                flex-direction: column;
                background-color: #ffffff;
                border: 1.2px solid #E4E8E8;
                box-sizing: border-box;
                border-radius: 4px;

                margin-left:15px;
                &:first-child{
                    margin-left:0;
                }

                padding:21px 30px;

                &.nopadding{
                    padding:21px 0;
                }

                &.fullwidth{
                    width: 100%;
                }
            
                h1{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 40px;
                    line-height: 44px;
                    color: #34626C;
                    margin-bottom: 5px;

                    span.grey{
                        color:#E4E8E8;
                        background-color: transparent !important;
                    }
                    span.fade{
                        color: #CF9DB5;
                    }
                }
                p{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    color: #6C6E6F;
                    margin:0;
                }
                

                &.single-value{
                    flex:1;
                }
                &.dual-value{
                    flex:1.5;
                }


                .title{
                    font-family: graphikregular;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.02em;
                    color: #6C6E6F;
                }
                
            }

        }


    }

</style>