<template>
    <div id="lancome-nav">
        <div class="logo-container">
            <img :src="logo_white" alt="Logo" class="logo">
        </div>
        <div class="stat-container" v-if="stats.length > 0">
            <div v-for="element in stats" :key="element.uuid" class="stat">
                <h1>{{element.value | numFormat}}</h1>
                <h2>{{element.label}}</h2>
            </div>
        </div>
        <div class="profile-container">
            <h1>{{currentUser}}</h1>
            <a @click="handleLogout">Sign Out</a>
        </div>
    </div>
</template>
<script>
import logo_white from '../../assets/img/logo_white.svg';
import Auth from '../../lib/Auth.js';
import CONSTANTS from '../../lib/Constants.js';
//import axios from 'axios';
import SandiaEventModel from '../../lib/SandiaEventModel';

export default {
    data(){
        return {
            CONSTANTS,
            logo_white,
            currentUser: localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName'),
            stats:[
                {
                    label:'Events',
                    value:0,
                    isPercent:false
                },
                {
                    label:'Total Registrations',
                    value:0,
                    isPercent:false
                }
            ],
            loading:false
        }
    },
    methods:{
        handleLogout(){
            Auth.clearCredentials();
            this.$router.push({name:'Login'});
        },
        async fetchStats(){
            
            try{
                let eventTotal = await SandiaEventModel.getEventCount();
                this.$set(this.stats[0], 'value', eventTotal);
            }
            catch(e){
                console.error(e);
            }
            try{
                let attendeeTotal = await SandiaEventModel.getAttendeeCount();
                this.$set(this.stats[1], 'value', attendeeTotal);
            }
            catch(e){
                console.error(e);
            }
        }
    },
    mounted(){
        this.fetchStats();
    }
}
</script>
<style lang="scss">
#lancome-nav{
    background-color: #000;
    width: 100%;
    display: flex;
    padding:20px 50px;

    .logo{
        max-height: 100%;
    }

    .logo-container{
        display: flex;
        flex:1;
    }
    .stat-container{
        display:flex;
        border-left: 2px solid rgba(196, 196, 196, 0.1);
        .stat{
            color:white;
            padding:0px 12px;
            h1{
                font-family: graphikmedium;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
            }
            h2{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;

            }
        }
    }
    .profile-container{
        display: flex;
        flex-direction: column;
        color:white;
        border-left: 2px solid rgba(196, 196, 196, 0.1);
        padding:0 0 0 12px;
        text-align: left;
        h1{
            font-family: graphikmedium;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;

        }
        a{
            font-family: graphikregular;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color:white;



            text-decoration: underline;
            &:hover{
                cursor: pointer;
            }
        }
    }
}
</style>