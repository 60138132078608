import axios from 'axios';
import CONSTANTS from './Constants';

const EventModel = {
    getEventCount: async () =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/event/count`;              
                axios.get(url).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getAttendeeCount: async () =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/attendee/count`;              
                axios.get(url).then((result)=>{
                    let data = result.data;
                    resolve(data.registered);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getAttendeeCheckins: async () =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/attendee/count`;              
                axios.get(url).then((result)=>{
                    let data = result.data;
                    resolve(data.checkedin);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getEvents: async () =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/event/data`;              
                axios.get(url).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getEvent: async (eventID) =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/event/${eventID}`;              
                axios.get(url).then((result)=>{
                    resolve(result.data[0]);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    },
    getEventAttendees: async (eventID, config = null) =>{
        return new Promise((resolve, reject)=>{
            try{
                //let event_id = eventObj.sandia;
                const url = CONSTANTS.SANDIA_API_BASE2 + `/attendee/search/${eventID}`;              
                axios.post(url, config).then((result)=>{
                    resolve(result.data);
                }).catch((e)=>{
                    reject(e);
                });
            }
            catch(err){
                reject(err);
            }
        });
    }
}
export default EventModel