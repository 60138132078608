<template>
    <div id="lancome-admin-main">
        <Loading :show="showLoader"/>
        <NavBar />
        <!-- <pre>{{events}}</pre> -->
        <div class="event-container">
            
                <div v-for="element in events"
                    :key="element.uuid"
                    class="event"
                >
                    <h1 class="event-name">{{ (element.name)?element.name:"Event Name"}}</h1>
                    <h2 class="event-date">{{(element.event_date)&&element.event_date | formatETDateTime}}</h2>
                    <label class="event-registrations">{{(element && element.attendees)?element.attendees + ' Registered' : 'Registered - N/A'}}</label> 
                    <!-- <label class="event-registrations">{{(element && element.attendees && element.attendees.confirmed)?element.attendees.confirmed + ' Confirmed' : 'Confirmed - N/A'}}</label> -->
                    <!-- <label class="event-checkins">{{(element && element.attendees && element.attendees.checkin)?element.attendees.checkin + ' Checked - in' : 'Checkins - N/A'}}</label> -->

                    <v-btn @click="navigateToEvent(element)" class="event-link-button" block outlined >GO TO EVENT</v-btn>
                </div>
           
        </div>
    </div>
</template>
<script>
import NavBar from '../components/NavBar/NavBar.vue';
import 'animate.css';
import Loading from '../components/Loading/Loading.vue';
// import axios from 'axios';
import CONSTANTS from '../lib/Constants.js'
// import EventModel from '../lib/EventModel.js'
import SandiaEventModel from '../lib/SandiaEventModel.js'


export default {
    components:{
        NavBar,
        Loading
    },
    data(){
        return {
            events:[],
            showLoader:false,
            CONSTANTS
        }
    },
    methods:{
        async fetchEvents(){

            let events = await SandiaEventModel.getEvents();
            if(Array.isArray(events) && events.length > 0){
                this.events = events;
            }
            else{
                this.events = [];
            }


            // return new Promise((resolve,reject)=>{
            //     this.showLoader = true;
            //     let url = this.CONSTANTS.API_MONGO_BASE_URL + 'api/events';
                
                
            //     let config = {
            //         headers : {
            //             "x-token-auth" : localStorage.getItem('token')
            //         }
            //     }
            //     axios.get(url,config).then(async (result)=>{

            //         // console.log(result);
            //         if(result.data){
            //             this.events = result.data.data.items.filter(el=>{
            //                 if(el.active){
            //                     return el;
            //                 }
            //             });
            //             await Promise.all(this.events.map(async event=>{
            //                 let attendees = await EventModel.getAttendeeCount(event.sandia);
            //                 this.$set(event,'attendees', attendees);
            //             }));
            //             // console.log(this.events);
            //             resolve();
                        
            //         }
            //         reject();

            //     })
            //     .catch(error=>{
            //         // error.response.data.error.detail || "Error: Invalid credentials";
            //         alert('An error has ocurred');
            //         console.error(error);
            //         reject(error);
            //     })
            //     // .finally(()=>{
            //     //     this.showLoader = false;
            //     // });
            // });
        },
        navigateToEvent(event){
            //alert(JSON.stringify(event));
            //this.$router.push({name:'AdminEventRegistrants', params:{event:event}});
            // this.$router.push({name:'AdminEventRegistrants', props:{event:event}});games
            
            
            this.$router.push({ path: `/admin/event/${event.id}/registrations` })
            // this.$router.push({ path: `/admin/event/${event._id}/games` })
            
        },
    },
    async mounted(){
        try{
            this.showLoader = true;
            await this.fetchEvents();
        }
        catch(error){
            console.error(error);
            debugger;
        }
        finally{
            this.showLoader = false;
        }
                    
       
        
    }
}
</script>
<style lang="scss">
#lancome-admin-main{
    .animated-element{
        transition: all 5s;
    }


    .event-container{
        // margin-top:40px;
        display:flex;
        flex-flow: row wrap;
        max-width: 1200px;
        margin: 40px auto 0 auto;
        justify-content: center;
        position:relative;

        // @media screen and (min-width: 600px){
        //     &{
        //         margin-left:144px;
        //         justify-content: flex-start;
        //     }
        // }

        .event{
            min-height: 224px;
            width:90%;
            // width: 298px;
            border-radius: 8px;
            background-color: #fff;
            margin:15px;
            border: 1px solid #E4E8E8;
            box-shadow: 0px 4px 16px rgba(0, 165, 156, 0.05);
            position:relative;

            button{
                position: absolute;
                bottom: 15px;
                left: 15px;
                // margin-bottom: 15px;
            }

            @media screen and (min-width: 600px){
                &{
                    width: 298px;
                }
            }



            .event-name{
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                margin-top: 29px;
                text-align: center;
                margin-bottom: 3px;
                padding: 0 15px;
            }

            .event-date{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 12px;
            }

            .event-registrations{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
                color: #4CBDFB;
                display:block;
                margin-bottom: 4px;
            }

            .event-checkins{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
                color: #35D3B6;
                display:block;
            }

            .event-link-button{
                min-width: 90% !important;
                margin: 0 auto;
                margin-top:30px;
                

                .v-btn__content{
                    text-transform: uppercase;
                    color: #34626C !important;
                    font-family: 'circular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 145.47%;
                }
                &.theme--light.v-btn{
                    color: #34626C !important;
                }
            }
            

        }

    }

}

</style>