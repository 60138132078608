<template>
    <div class="lancome-textfield" :class="{error:isInvalid, focused:focus}">
        
        <label v-if="txtLabel">{{txtLabel}}</label>
        <div class="form-input" :class="{focused:focus}">
            <input 
                :type="inputType"
                :value="content" 
                :disabled="boolDisabled" 
                @input="handleInput"
                :placeholder="txtPlaceholder"
                @focus="focus = true"
                @blur="focus = false"
            >
        </div>
        <div class="error-message" v-if="txtErrorMessage && txtErrorMessage.length > 0">{{txtErrorMessage}}</div>
    </div>
</template>
<script>
export default {
    props: ['value','label','placeholder','disabled','type','invalid','errorMessage'],
    data () {
        return {
            content: this.value || '',
            txtLabel: this.label || '',
            txtPlaceholder: this.placeholder || '',
            boolDisabled: this.disabled || false,
            inputType: this.type || 'text',
            // errorsArray: this.errors || [],
            focus:false,
            isInvalid : (typeof(this.invalid) == 'boolean') ? this.invalid: false,
            txtErrorMessage : this.errorMessage || null
        }
    },
    methods: {
        handleInput (e) {
            this.content = e.target.value || '';
            this.$emit('input', this.content);
        }
    },
    watch:{
        invalid(){
            this.isInvalid = (typeof(this.invalid) == 'boolean') ? this.invalid: false;
        },
        errorMessage(){
            this.txtErrorMessage = this.errorMessage || null;
        }
    }
}
</script>
<style lang="scss">
    // @font-face {
    //     font-family: "graphikmedium";
    //     src: local("graphikmedium"),
    //     url(../../fonts/Graphik-Medium.ttf) format("truetype");
    // }
    .lancome-textfield{
        
        label{
            font-family: 'graphikmedium';
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            display:block;
            text-transform: capitalize;
            margin-bottom:3px;
        }

        .form-input{
            border: 1.4px solid #E1E6E8;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 5px;
            &.focused{
                border-width: 2.4px;
            }
            
            input{
                padding:13px 16px;
                outline:none;
                border:none;
                color: black;
                font-family: 'graphikregular';
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;
                text-align: left;

                &::placeholder{
                    color: #A2A9A9;
                    font-family: graphikregular;
                }
                

            }

            &.error{
                border-color: red;
            }
        }
        .error-message{
            font-size: small;
            color:red;
            font-family: graphikregular;
            margin-top:2px;
            opacity: 0.8;
        }
        &.error{
            
            .form-input{
                border-color: red;
            }
            input{
                color:red;
            }

            label{
                color:red;
            }
            
        }
    }
</style>
