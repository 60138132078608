<template>
    <div id="event-questions">
        <!-- {{event.attendees}} -->
        <!-- <h1>Registrations</h1> -->
        <div class="cta-container">
            <div class="info-container">
                <h1>Messages</h1>
                <p>{{totalMessages}} Total Messages - Refreshing in: {{refreshTimer}}</p>
            </div>
            
        </div>
        <div class="filter-container">
            
            <div class="filter-group stretch">
                <div class="filter stretch">
                    <v-text-field
                        v-model="queryString"
                        color="#34626C"
                        height="40"
                        outlined
                        block
                        prepend-inner-icon="search"
                        placeholder="Enter search string"
                    ></v-text-field>

                </div>
                
            </div>
            
        </div>
        <br>
        <!-- <div class="no-data" v-if="attendees.length === 0">
            <h1>No results found</h1>
        </div> -->
        <!-- {{order_by}} - {{order_dir}} -->
        <div class="results-table" >
            <!-- <p>Total Results: {{totalResults}}</p> -->
            <div class="results">

                <template>
                <v-data-table
                    :headers="headers"
                    :items="eventMessages"
                    :options.sync="options"
                    
                    :loading="loading"
                    class="elevation-0">

                    <template v-slot:item._id="{ item }">
                        <!-- <v-icon @click.stop="updateFavoriteStatus(item)" class="question-status" :class="{highlighted: item.favorite}">mdi-star</v-icon> -->
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" @click.stop="deleteChatMessage(item)" class="question-delete" >delete</v-icon>     
                            </template>
                            <span>Delete</span>
                        </v-tooltip>                  
                    </template>
                    
                    <!-- <template v-slot:item.status="{ item }">
                        <v-checkbox
                            color="#34626C"
                            :input-value="item.status === 'answered'"
                            @click.stop="updateStatus(item)"
                            
                        ></v-checkbox>
                    </template> -->

                    <template v-slot:item.createdAt="{ item }">
                        {{item.createdAt | formatDateTime}}
                    </template>

                </v-data-table>
                </template>

            </div>

            
        </div>


        


    </div>
</template>
<script>
import EventModel from '../../lib/EventModel';
// import SandiaEventModel from '../../lib/SandiaEventModel';
import ChatModel from '../../lib/ChatModel';
import io from 'socket.io-client';
import CONSTANTS from '../../lib/Constants'
export default {
    
    props:['event', 'eventMongo'],
    data(){
        return{
            CONSTANTS,
            queryString:'',
            questionStatus:'',
            favoriteStatus:'',
            statusFilter:['all','pending','answered'],
            favoriteFilter:['all','highlighted'],
            awaitingSearch:false,
            options:{},
            headers:[
                { text: 'Created At', value: 'createdAt', sortable: true },
                { text: 'User', value: 'user', sortable: true },
                { text: 'Message', value: 'message', sortable: true },
                { text: 'Action', value: '_id', sortable: true, align: 'center' },
                // { text: 'Status', value: 'status', sortable: true, align: 'center' },
            ],
            eventQuestions:[],
            eventMessages:[],
            loading:false,
            refreshTimer:10,
            interval:null,
            socket: null,//io(CONSTANTS.SOCKET_SERVER_URL,{transports: ['websocket', 'polling']}),
            // timer:null
        }
    },
    
    computed:{
        totalQuestions(){
            return this.eventQuestions.length;
        },
        totalMessages(){
            return this.eventMessages.length;
        }
    },
    methods:{

        async loadQuestionData(){
            if(!this.event || !this.event._id){
                return;
            }
            try{
                this.loading = true;
                let filter = {};
                if(this.queryString.trim().length > 0){
                    filter.query = this.queryString;
                }


                if(this.questionStatus.trim().length > 0 && this.questionStatus !== 'all'){
                    filter.status = this.questionStatus;
                }

                if(this.favoriteStatus === 'highlighted'){
                    filter.highlighted = true;
                }

                this.eventQuestions = await EventModel.getEventQuestions(this.event,filter);
            }
            catch(error){
                console.error(error);
            }
            finally{
                this.loading = false;
            }
        },

        async updateStatus(item){
            //alert(JSON.stringify(item));
            let newStatus = (item.status === 'answered')?'pending':'answered';
            item.status = newStatus;
            await EventModel.updateQuestionStatus(item);

            // alert('emitting');
            if(!this.loading){
                console.log(this.socket.emit('message:update',{
                    _id : item._id,
                    event: this.event._id,
                    status: newStatus
                }));
            }

        },
        async updateFavoriteStatus(item){
            //alert(JSON.stringify(item));
            let newStatus = (item.favorite)?false:true;
            item.favorite = newStatus;

            await EventModel.updateQuestionFavoriteStatus(item);

            if(!this.loading){
                console.log(this.socket.emit('message:update',{
                    _id : item._id,
                    event: this.event._id,
                    favorite: newStatus
                }));
            }
            

        },

        registerEvents(){

            if(!this.socket){
                console.warn('NO SOCKET YET!!!');
                return;
            }

            this.socket.on('connect', ()=>{
                console.log('connected'); 
                console.log(this.eventMongo);
            });

            this.socket.emit('chat:signin', {
                type: 'signin',
                event: `ostt-${this.eventMongo._id}`,
                id: 1
            });

            this.socket.on('chat:room',()=>{
                    console.log('Connected to ');
                    console.log(`ostt-${this.eventMongo._id}`);
                });
            // Reconnect
            this.socket.on('reconnect', () => {
                this.socket.emit('chat:signin',{
                    type: 'reconnect',
                    event: `ostt-${this.eventMongo._id}`,
                    id: 1
                });
            });

            this.socket.on('message:received', async () =>{
                this.loading = true;
                await this.loadQuestionData();
                this.loading = false;
            });
            this.socket.on('message:updated', async (data) =>{
                try{
                    this.loading = true;

                    if(!Array.isArray(this.eventQuestions)){
                        this.eventQuestions = [];
                    }
                    let questions = [...this.eventQuestions];
                    let position = questions.findIndex(el=>el._id === data._id);
                    if(position > -1){
                        questions[position] = data;
                        this.$set(questions,position,data);
                    }
                    await this.loadQuestionData();
                }
                finally{
                    this.loading = false;
                }
                
            });
        },

        async loadMessages(){
            // eventMessages
            if(!this.event || !this.event.id){
                return;
            }
            try{
                this.loading = true;
                clearInterval(this.tick);
                this.refreshTimer = 10;
                let filter = {};
                if(this.queryString.trim().length > 0){
                    filter.query = this.queryString.trim();
                }
                this.eventMessages = await ChatModel.getEventChatMessages(this.event.id, filter);
            }
            catch(error){
                console.error(error);
            }
            finally{
                this.loading = false;
                if(!this.interval)
                this.interval = setInterval(this.tick,1000);
            }
        },

        async deleteChatMessage(message){
            if(!message){
                return;
            }
            try{
                console.log(this.eventMongo);
                // debugger;
                this.loading = true;
                //await ChatModel.deleteEventChatMessages(message._id);
                console.log(this.socket.emit('chat:remove', { _id : message._id, event:this.eventMongo._id }));

                await this.loadMessages();
            }
            catch(error){
                console.error(error);
            }
            finally{
                this.loading = false;
            }
        },
        tick(){
            
            let remainingTime = this.refreshTimer - 1;
            if(remainingTime < 1){
                remainingTime = 10;
                this.loadMessages();
            }
            // else{
            //     remainingTime -= 1;
            // }
            this.refreshTimer = remainingTime;
            // console.log('tick', this.refreshTimer, remainingTime);
        }


    },
    watch:{
        
        queryString() {
            if (!this.awaitingSearch) {
            setTimeout(() => {
                this.currentPage = 1;
                //this.loadQuestionData();
                this.loadMessages();
                this.awaitingSearch = false;
            }, 1000); // 1 sec delay
            }
            this.awaitingSearch = true;
        },
        questionStatus(newVal){
            if(newVal === 'all'){
                this.questionStatus = '';
            }
            this.loadQuestionData();
        },
        favoriteStatus(newVal){
            if(newVal === 'all'){
                this.favoriteStatus = '';
            }
            this.loadQuestionData();
        },
        
        options: {
            handler (newVal) {
                // console.log(this.options);
                // debugger;
                this.order_by = (newVal && newVal.sortBy && newVal.sortBy[0])?newVal.sortBy[0]:'email';
                this.order_dir = (newVal && newVal.sortDesc && newVal.sortDesc[0])?'DESC':'ASC';
                //this.loadQuestionData();
                this.loadMessages();
            },
            deep: true,
        },
        event(){
            if(this.event){
                this.loadMessages();
            }
        },
        eventMongo(){
            if(this.eventMongo && this.eventMongo._id){
                this.socket = io(CONSTANTS.SOCKET_SERVER_URL,{secure: true, transports: ['websocket', 'polling']});
                this.registerEvents();
            }
            
        }
    },
    async mounted(){
        this.interval = setInterval(this.tick,1000);
        await this.loadMessages();
        if(this.eventMongo && this.eventMongo._id){
                this.socket = io(CONSTANTS.SOCKET_SERVER_URL,{secure: true, transports: ['websocket', 'polling']});
                this.registerEvents();
            }
        // this.registerEvents();
        // this.timer = setTimeout(()=>{ this.show = false; console.log('Timer');}, 5000);
        //this.totalResults = this.attendees.length;
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval);
        }
    }
    
}
</script>
<style lang="scss">
#event-questions{
    margin-bottom:80px;

    .question-status{
        &:hover,
        &.highlighted{
            cursor: pointer;
            color:#FB8D00;
        }
    }
    .question-delete{
        &:hover{
            cursor: pointer;
            opacity: 0.6;
        }
    }


    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
        color: #E4E8E8;
    }

    margin: 43px 0 0 auto;
    // width: 90%;
    // max-width: 1200px;
    .cta-container{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 21px;

        .info-container{
            
            flex: 1;
            display: flex;
            justify-content: center;
            flex-flow: column;
            h1{
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #363738;
                margin:0;
            }
            p{
                font-family: graphikregular;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #6C6E6F;
                margin:0;
            }
        }
    }

    .filter-container{
        margin-top:5px;
        display:flex;
        flex-flow: row wrap;
        .filter-group{
            display:flex;
            flex-flow: row wrap;
            .filter{
                display:flex;
                flex-flow: row wrap;
                margin-left: 5px;


                .v-text-field__details{
                    display: none;
                }


                .v-input__slot{
                    background-color:white;
                }

                .v-input__prepend-inner{
                    z-index:10;
                }


            }
        }
    }

    .single-radio-input{
        height: 40px;
        display:flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E4E8E8;
        background-color: white;
        padding: 0 12px;
        border-radius: 4px;
    }

    .v-input--selection-controls__input{
        .v-label{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 140%;
        }
    }

    .stretch{
        flex-grow:1;
        flex-shrink: 0;
    }
    .noshrink{
        flex-shrink: 0;
    }

    .outlined {
        border: 1px solid red;
        border-radius:50%;
        height: 56px;
        width: 56px;
    }

    .no-data{
        border: 1px solid #E4E8E8;
        background-color: white;
        padding: 0 12px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height:60px;

        h1{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            /* identical to box height */

            text-align: center;
        }
    }

    .controls{
        margin-top:25px;
        display: flex;
        .result-amount, .go-to-page{
            width:10%;
        }
        .pagination{
            display:flex;
            flex:1;
            justify-content: center;
        }

        .result-amount{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            display: flex;
            align-items: center;
            label{
                margin-right: 5px;
            }

            .v-text-field__details{
                display:none;
            }
            .mdi-menu-down::before{
                background: none;
                width:0;
                height:0;
            }
            .v-input__append-inner{
                display:none;
            }

        }
        .go-to-page{
            font-family: graphikregular;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            display: flex;
            align-items: center;
            label{
                margin-right: 5px;
            }

            .v-text-field__details{
                display:none;
            }
            .mdi-menu-down::before{
                background: none;
                width:0;
                height:0;
            }
            .v-input__append-inner{
                display:none;
            }
        }


        .v-pagination__navigation,
        .v-pagination__item{
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
        .v-pagination__item.v-pagination__item--active{
            color:#34626C;
            background-color: transparent !important;
            // text-decoration: underline;
            border-bottom: 1px solid #34626C;
            border-radius: 0;
        }

        .v-icon.notranslate.mdi.mdi-chevron-left,
        .v-icon.notranslate.mdi.mdi-chevron-right{
            color:#34626C;
        }


    }

    th{
        background-color: #E4E8E8;
        text-transform: uppercase;
        font-family: graphikmedium;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.25em;
        text-align: center;
        color: #34626C;
    }

    td{
        font-family: graphikregular;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

    }

    .registered, .confirmed{
        color: #23ADFA;
    }

    .checkin{
        color:#19CCAC;
    }

    .create-edit-form{
        background: #FFFFFF;
        // box-shadow: 0px 20px 40px rgba(255, 240, 250, 0.8);
        border-radius: 8px;
        padding:25px;

        width:95vw;
        max-width: 465px;

        .title{
            margin-bottom:15px;
            display:flex;
            justify-content: space-between;
            align-items: center;

            

            i:hover{
                cursor:pointer;
            }
            h1{
                color:#363738;
                font-family: graphikmedium;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                text-transform: capitalize;
            }
        }
        .lancome-textfield label{
            color:#363738;
        }
        .form-input > input{
            width: 100%;
        }

        .v-application.error,
        .lancome-textfield.error {
            background-color: transparent !important;
            label{
                color:red !important;
            }
        }
        color:#363738;
        *::placeholder{
                color:A2A9A9, 100%;
            }
        .v-select__selections > *{
            color:#363738;
            *::placeholder{
                color:A2A9A9, 100%;
            }
        }

        .v-input__control{
            color:#E4E8E8;
        }

        label{
            font-family: graphikmedium;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;
            color: #363738;
            display: block;
        }

        

        .goals{
            display: flex;
            justify-content: row wrap;
            .col{
                min-width: 50%;

            }


            .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
            color: #E4E8E8;
            }
            .v-icon.v-icon{
                color: #E4E8E8;
            }
            .v-messages.theme--light{
                display:none;
            }
            .v-input__slot{
                margin-bottom:0;
            }



        }

        .v-input--checkbox{
            .v-label{
                font-family: graphikregular;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0em;
                color:#363738;
            }
        }

        .controls{
            display: flex;
            justify-content: space-between;

            .v-btn.v-btn--flat.v-btn--plain{
                span{
                    color: #B6BDBD !important;
                }
            }

        }

        




    }
}
</style>