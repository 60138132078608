let currentEnv = (()=>{
    let origin = window.location.host.toLowerCase();
    let env;
    if(origin.indexOf('localhost') > -1) {
        env =  "development"
    }
    else if(origin.indexOf('staging') > -1) {
        env =  "staging"
    }
    else{
        env = "production"
    }
    // env = "production";
    console.log("ENVIRONMENT:::::"+env);
    // return "production";
    return env;
})();
let CONSTANTS;
if(currentEnv !== "production"){

    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '85bdf869a799bc01',
        API_MONGO_BASE_URL: 'http://45.55.56.174:7050/',
        SOCKET_SERVER_URL: 'https://ourstoriestotell.com',// 'http://45.55.56.174:7050', // 'https://ourstoriestotell.com/',
        SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_API_BASE2: 'https://api.staging.hboourstoriestotell.csmexternal.com',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1465
        
        // SOCKET_SERVER_URL: 'http://localhost:3000',
        

    }
}
else{
    CONSTANTS = {
        CURRENT_ENV: currentEnv,
        API_USER_TOKEN: '953401f5698f39ba',
        API_MONGO_BASE_URL: 'https://ourstoriestotell.com/',
        SOCKET_SERVER_URL: 'https://ourstoriestotell.com',
        SANDIA_API_BASE: 'https://api2.lancomepassporttoparis.com/',
        SANDIA_API_BASE2: 'https://api.staging.hboourstoriestotell.csmexternal.com',
        SANDIA_CAMPAIGN_ID: 104,
        SANDIA_EVENT_ID: 1465
    };
}


export default CONSTANTS;

